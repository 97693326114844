@font-face { 
  font-family: OPTICopperplate; 
  src: local(OPTICopperplate),
  url('./fonts/OPTICopperplate/OPTICopperplate.ttf') format('truetype'); 
} 

body {
  margin: 0;
  font-family: OPTICopperplate, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

code {
  font-family: OPTICopperplate, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  top: 0;
  position: absolute;
  width: 100%;
  height: calc(100%);
}

.container video {
  width: 100%;
  position: absolute;
}

.container button {
  width: 76px;
  height: 76px;

  border-radius: 50%;

  background: rgba(255, 255, 255, 0.9);
}

.container-cam {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.container img {
  position: absolute;
	/* top: 50%; */
}

.box {
  --b:1px;   /* thickness of the border */
  --c:white;   /* color of the border */
  --w:20px;  /* width of border */
  

  border:var(--b) solid transparent; /* space for the border */
  --g:#0000 90deg,var(--c) 0;
  background:
    conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--g)) 0    0,
    conic-gradient(from 180deg at top    var(--b) right var(--b),var(--g)) 100% 0,
    conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--g)) 0    100%,
    conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--g)) 100% 100%;
  background-size:var(--w) var(--w);
  background-origin:border-box;
  background-repeat:no-repeat;
  
  /*Irrelevant code*/  
  width:200px;
  height:200px;
  box-sizing:border-box;
  margin-top: 50%;
  display:inline-flex;
  font-size:30px;
  justify-content:center;
  align-items:center;
  line-height:90px;
}

.container span {
  position: absolute;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 14px;
  width: 100%;
  text-align: center;
}

.container .loading {
  height: 100vh;
}

.container .loading span {
  font-size: 18px;
}

.container span div {

  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container span span {
  width: 300px;
}

.container span p {
  width: 300px;
}

.container span img {
  position: relative;
  width: 30px;
  margin: 12px;
}

.briefing-container-desktop {
  height: calc(100%);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: url('./images/desktopBackground.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  top: 0;
  position: absolute;
}

.briefing-container-desktop .briefing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.briefing-container-desktop .briefing-content span {
  color: #ffff;

  width: 500px;
  text-align: center;
  line-height: 32px;
  font-size: 28px;

  position: absolute;
}

.briefing-container-desktop button {
  background: transparent;
}

.briefing-container {
  height: calc(100%);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* background: url('./images/fire.gif'), url('./images/backgroundBrifieng.svg') no-repeat center center fixed; */
  background: 
    url('./images/fireTop.gif') top no-repeat, 
    url('./images/fireBottom.gif') bottom no-repeat, 
    url('./images/backgroundBrifieng.svg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%, 100%, cover;

  top: 0;
  position: absolute;
}

@media only screen and (min-width: 600px) {
  .briefing-container img {
    width: 60%;
    height: auto;
    margin-left: 20%;
  }
}

@media only screen and (max-height: 540px) {
  .briefing-container img {
    margin-top: 50px;
    width: 55%;
    margin-left: 10% !important;
  }
}

.briefing-container img {
  max-width: 80%;
  height: auto;
  margin-left: 20%;
}

.header {
  position: fixed;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header img {
  margin: 12px;
  width: 30px;
}

.briefing-container .briefing-content {
  display: flex;

  flex-direction: column;
  align-items: center;

  padding: 20px;

  width: 256px;
  height: 239px;
  left: 85px;
  top: 147px;
  border-radius: 10px;

  color: white;
}

.briefing-container input#cameraFileInput{
  display: none;
}

.briefing-container input#cameraFileInput + label{
  margin-top: 20px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background: #ECECEC; 
  color: #000000;
  border: none;
  font-size: 18px;
  border-radius: 10px;
  width: 216px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.briefing-container .briefing-content h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
}

@media only screen and (min-width: 320px) {
  .briefing-container .briefing-content span {
    font-size: 12px;
    width: 300px;
  }
}


.briefing-container .briefing-content span {
  font-style: normal;
  font-size: 16px;
  text-align: center;
  /* margin-bottom: 20px; */
  line-height: 25px;
  font-weight: 700;
  /* width: 340px; */
}

.briefing-container .briefing-content p {
  font-style: normal;
  width: 144px;
  font-size: 0.563rem;
  line-height: 14px;
  text-align: center;

}

.logo-home {
  cursor: pointer;
}

.briefing-container button {
  height: 41px;
  font-size: 10px;

  position: absolute;

  background-color: transparent;
  border: 2px solid white;
  border-radius: 20px;
  color: white;

  align-items: center;
  justify-content: center;
  font-family: OPTICopperplate;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}