.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* background: url('../images/fire.gif'), url('../images/bgResult.svg') no-repeat center center fixed; */
  background: 
    url('../images/fireTop.gif') top no-repeat, 
    url('../images/fireBottom.gif') bottom no-repeat,
    url('../images/bgResult.svg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%, 100%, cover;

  top: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
  height: calc(100%);
}

.logo-home {
  cursor: pointer;
}

.container .header {
  display: flex;
  justify-content: space-between;
}

.result {
  line-height: 28px;
  width: 298px;
  padding: 0 20px;
}

.result-button {
  width: 227px !important;
  height: 48px !important;
  font-size: 13px;
  line-height: 14px;
  font-weight: 700;
  position: relative;
}

.container .content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container .container-img {
  width: 100%;
  position: relative;
  top: 0;
  margin-top: 200px
}

.container img .pentagram {
  background: #000000;
}

.container div {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container div span{
  font-size: 32px
}

@media only screen and (min-width: 320px) {
  .container .content span {
    font-size: 12px;
    width: 300px;
    padding: 0 0 !important;
  }
}

.container .content span {
  text-align: center;
  font-size: 20px;
  position: relative;
  padding: 0 20px;
  line-height: 26px;
  /* width: 390px; */
}

.container .header {
  position: absolute;

  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.container .header img {
  margin: 12px;
  width: 30px;
}

.container .content button {
  width: 192px;
  height: 41px;

  position: absolute;

  background-color: transparent;
  border: 2px solid white;
  border-radius: 20px;
  color: white;
  font-size: 8px;

  align-items: center;
  justify-content: center;
  font-family: OPTICopperplate;
}

.container .content button:last-child {
  margin-bottom: 65px;
}

button {
  margin-top: 20px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background: #ECECEC; 
  color: #000000;
  border: none;
  font-size: 10px;
  width: 216px;
  height: 56px;
}

button a {
  text-decoration: none;
  font-size: 8px;
  color: #fff;
  line-height: normal;
}