.container-header {
  background: transparent;

  display: flex;

  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.bm-menu-wrap {
  width: 100% !important;
  /* background: url('../images/fire.gif'), url('../images/backgroundBrifieng.svg') no-repeat center center; */
  background: 
    url('../images/fireTop.gif') top no-repeat, 
    url('../images/fireBottom.gif') bottom no-repeat, 
    url('../images/backgroundBrifieng.svg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%, 100%, cover;
  
  z-index: 1;
}

.my-burguer  {
  width: 50%;
}

.my-burguer button {
  width: 50% !important;
}

.my-menu .bm-item-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: calc(100%);
}

.my-menu span {
  color: #ffff;
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 60px;
}

.my-menu a {
  font-size: 18px;
  color: #ffff;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
  line-height: 32px;
  position: relative;
  backdrop-filter: blur(10px);
  filter: none;
}

@media only screen and (min-width: 320px) {
  .cross-button {
    right: 42% !important;
  }
}

@media only screen and (min-width: 375px) {
  .cross-button {
    right: 43% !important;
  }
}

@media only screen and (min-width: 425px) {
  .cross-button {
    right: 44% !important;
  }
}

@media only screen and (min-width: 600px) {
  .cross-button {
    right: 46% !important;
  }
}

@media only screen and (min-width: 1024px) {
  .cross-button {
    right: 48% !important;
  }
}

@media only screen and (min-width: 1080px) {
  .cross-button {
    right: 48.5% !important;
  }
}

.cross-button {
  width: 50px !important;
  height: 50px !important;
  background: url('../images/backMenu.svg') no-repeat !important;
  background-size: 100% 100%;
  top: 60% !important;
  right: 40%;
  margin-top: 5rem;
}

.back-button {
  margin: 80px 0 !important;
  cursor: pointer;
}

.container-header img {
  width: 30px;
  margin: 12px;
}

/* .container-header select {
  width: 45px;
  height: 18px;
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #ffff;
  margin-right: 12px;
} */

select {
  width: 45px;
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #ffff;
  margin-right: 12px;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 4px;
  text-overflow: '';
  appearance: none;
}

.container-header .select-wrapper {
  position: relative;
}

.container-header .select-wrapper::after {
  content: url('../images/dropdown.svg');
  font-size: 10px;
  top: 5px;
  right: 18px;
  position: absolute;
  color: #ffff;
}